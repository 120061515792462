.box-shadow1, .hover-box-shadow1:hover  {
  box-shadow: 0px 28px 80px rgba(0, 0, 0, 0.02), 0px 10.2205px 29.2013px rgba(0, 0, 0, 0.0137996), 0px 4.96184px 14.1767px rgba(0, 0, 0, 0.0111258), 0px 2.43239px 6.94968px rgba(0, 0, 0, 0.00887419), 0px 0.96177px 2.74791px rgba(0, 0, 0, 0.00620037);
}
.box-shadow2, .hover-box-shadow2:hover  {
  box-shadow: 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}
.box-shadow3, .hover-box-shadow3:hover {
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.12);
}
.box-shadow4, .hover-box-shadow4:hover {
  box-shadow: 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}
.box-shadow5, .hover-box-shadow5:hover {
  box-shadow: 0 0 14px 0 rgb(53 64 82 / 10%);
}
.box-shadow6, .hover-box-shadow6:hover {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
}
.overflow-hidden {overflow: hidden;}
.z-index-0 {z-index: 0;}
.z-index-1 {z-index: 1;}
.z-index-3 {z-index: 3;}
.z-index-5 {z-index: 5;}
.grid {display: grid;}
.grid-col-1 {grid-column: 1;}
.grid-col-2 {grid-column: 2;}
.grid-col-3 {grid-column: 3;}
.grid-col-4 {grid-column: 4;}
.grid-col-5 {grid-column: 5;}
.grid-col-6 {grid-column: 6;}
.grid-row-1 {grid-row: 1;}
.grid-row-2 {grid-row: 2;}
.grid-row-3 {grid-row: 3;}
.grid-col-2-4 {grid-column: 2 / 4;}
.grid-column-start-1 {grid-column-start: 1;}
.grid-column-start-2 {grid-column-start: 2;}
.grid-col-span-2 {grid-column: span 2;}
.grid-row-span-2 {grid-row: span 2;}
.grid-col-span-3 {grid-column: span 3;}
.grid-col-1-span-2 {grid-column: 1 / span 2;}
.grid-col-2-span-2 {grid-column: 2 / span 2;}
.grid-col-full {grid-column: 1 / -1;}
.width-available {width: -webkit-fill-available;}
.width-100 {width: 100%;}
.width-fit-content, .w-fit {width: fit-content;}
.width-max-content {width: max-content;}
.text-left {text-align: left;}
.absolute {position: absolute;}
.relative {position: relative;}
.text-justify {text-align: justify;}
.text-center {text-align: center;}
.text-end {text-align: end;}
.text-start {text-align: start;}
.inherit {display: inherit;}
.grid-36px-1fr {grid-template-columns: 36px 1fr;}
.grid-80-30-30 {
  grid-template-columns: 80px 30px 30px;
}
.grid-70-380-140-100-150-100-100 {
  grid-template-columns: 70px 380px 140px 100px 150px 100px 100px
}
.grid-20-190-140 {
  grid-template-columns: 20px 190px 140px;
}
.grid-108-108 {
  grid-template-columns: 108px 108px;
}
.grid-2fr, .grid-cols-2 {
  grid-template-columns: 1fr 1fr;
}
.grid-3fr, .grid-cols-3 {
  grid-template-columns: 1fr 1fr 1fr;
}
.grid-fr-20px {
  grid-template-columns: 1fr 20px;
}
.grid-fr-54px {
  grid-template-columns: 1fr 54px;
}
.grid-54px-fr {
  grid-template-columns: 54px 1fr;
}
.grid-fr-200px {
  grid-template-columns: 1fr 200px;
}
.grid-fr-240px {
  grid-template-columns: 1fr 240px;
}
.grid-fr-50px-50px-50px {
  grid-template-columns: 1fr 50px 50px 50px;
}
.grid-32px-883px-1fr {
  grid-template-columns: 32px 885px 1fr;
}
.grid-rows-30px-32px-40px {
  grid-template-rows: 30px 32px 40px
}
.grid-rows-80px {
  grid-template-rows: 80px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.align-items-center, .items-center {align-items: center;}
.align-items-end {align-items: flex-end;}
.align-items-baseline {align-items: baseline;}
.align-items-start {align-items: flex-start;}
.justify-content-start {justify-content: flex-start;}
.justify-content-end {justify-content: flex-end;}
.justify-content-center, .justify-center {justify-content: center;}
.justify-self-start {justify-self: flex-start;}
.justify-self-end {justify-self: flex-end;}
.justify-self-center {justify-self: center;}
.align-self-center {align-self: center;}
.align-self-end {align-self: flex-end;}
.align-self-start {align-self: flex-start;}
.display-none {display: none;}
.display-block {display: block;}
.fixed {position: fixed;}
.space-between, .justify-content-space-between, .justify-between {justify-content: space-between;}
.space-around {justify-content: space-around;}
.rotate-180 {transform: rotate(-180deg);}
.transition-400 {transition-duration: 400ms;}
.b-solid {border-style: solid;}
.b-dashed {border-style: dashed;}
.b-bottom-solid {border-bottom-style: solid;}
.b-top-solid {border-top-style: solid;}
.no-underline {text-decoration-line: none;}
.underline {
  text-decoration: underline;
}
.text-decoration-none {
  text-decoration: none;
}
.times-new-roman {
  font-family: 'Times New Roman', Times, serif;
}
.scrollbar-thin-y-gray {
  overflow-y: scroll;
  scrollbar-width: auto;
}
.scrollbar-thin-y-gray::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.scrollbar-thin-y-gray::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}
.scrollbar-thin-y-gray:hover::-webkit-scrollbar-thumb {
  background: gray;
}
.none {
  display: none;
}
.hover-underline:hover {
  text-decoration: underline;
}
.opacity-5 {
  opacity: 0.5;
}
.opacity-65 {
  opacity: 0.65;
}
.opacity-0 {
  opacity: 0;
}
.hover-scale-102:hover {
  transform: scale(1.02);
}
.hover-scale-105:hover {
  transform: scale(1.05);
}
.hover-shadow:hover {
  background-color: #FAFAFA;
}
.hover-bc-solid-default:hover {
  border-color: #0D2549;
  border-style: solid;
}
.transition-all-1s {
  -o-transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.list-style-none {
  list-style: none;
}
.pointer {cursor: pointer;}
.default {cursor: default;}