.b-0 {border-width: 0px;}
.b-05 {border-width: 0.5px;}
.b-1 {border-width: 1px;}
.b-2 {border-width: 2px;}
.b-5 {border-width: 5px;}
.br-2 {border-radius: 2px;}
.br-3 {border-radius: 3px;}
.br-4 {border-radius: 4px;}
.br-5 {border-radius: 5px;}
.br-6 {border-radius: 6px;}
.br-8 {border-radius: 8px;}
.br-12 {border-radius: 12px;}
.br-13 {border-radius: 13px;}
.br-15 {border-radius: 15px;}
.br-16 {border-radius: 16px;}
.br-17 {border-radius: 17px;}
.br-20 {border-radius: 20px;}
.br-22 {border-radius: 22px;}
.br-24 {border-radius: 24px;}
.br-48 {border-radius: 48px;}
.br-top-left-4 {border-top-left-radius: 4px;}
.br-top-left-8 {border-top-left-radius: 8px;}
.br-bottom-left-8 {border-bottom-left-radius: 8px;}
.br-top-right-4 {border-top-right-radius: 4px;}
.br-top-right-8 {border-top-right-radius: 8px;}
.br-bottom-right-8 {border-bottom-right-radius: 8px;}
.brl-3 {border-top-left-radius: 3px;border-bottom-left-radius: 3px;}
.brr-3 {border-top-right-radius: 3px;border-bottom-right-radius: 3px;}
.br-left-8 {border-top-left-radius: 8px;border-bottom-left-radius: 8px;}
.br-right-8 {border-top-right-radius: 8px;border-bottom-right-radius: 8px;}
.b-bottom-width-1, .b-bottom-1 {border-bottom-width: 1px;}
.b-bottom-width-2 {border-bottom-width: 2px;}
.b-top-width-1 {border-top-width: 1px;}
.b-none {border: none;}
.gap-4 {gap: 4px;}
.gap-8 {gap: 8px;}
.gap-10 {gap: 10px;}
.gap-12 {gap: 12px;}
.gap-20 {gap: 20px;}
.gap-24 {gap: 24px;}
.row-gap-6 {row-gap: 6px;}
.h-1px {height: 1px;}
.h-4px {height: 4px;}
.h-20px {height: 20px;}
.h-21px {height: 21px;}
.h-22px {height: 22px;}
.h-23px {height: 23px;}
.h-24px {height: 24px;}
.h-25px {height: 25px;}
.h-28px {height: 28px;}
.h-29px {height: 29px;}
.h-30px {height: 30px;}
.h-32px {height: 32px;}
.h-33px {height: 33px;}
.h-34px {height: 34px;}
.h-36px {height: 36px;}
.h-37px {height: 37px;}
.h-60px {height: 60px;}
.h-84px {height: 84px;}
.h-120px {height: 120px;}
.h-127px {height: 127px;}
.h-200px {height: 200px;}
.h-400px {height: 400px;}
.h-100percent {height: 100%;}
.w-4px {width: 4px;}
.w-20px {width: 20px;}
.w-28px {width: 28px;}
.w-30px {width: 30px;}
.w-32px {width: 32px;}
.w-36px {width: 36px;}
.w-37px {width: 37px;}
.w-60px {width: 60px;}
.w-80px {width: 80px;}
.w-95px {width: 95px;}
.w-100px {width: 100px;}
.w-110px {width: 110px;}
.w-120px {width: 120px;}
.w-124px {width: 124px;}
.w-130px {width: 130px;}
.w-192px {width: 192px;}
.w-200px {width: 200px;}
.w-250px {width: 250px;}
.w-273px {width: 273px;}
.w-290px {width: 290px;}
.w-350px {width: 350px;}
.w-500px {width: 500px;}
.w-656px {width: 656px;}
.w-700px {width: 700px;}
.w-890px {width: 890px;}
.w-883px {width: 883px;}
.w-1280px {width: 1280px;}
.h-min {height: min-content;}
.w-max {width: max-content;}
.w-available {width: -webkit-fill-available;}
.h-available {height: -webkit-fill-available;}
.h-fit-content {height: fit-content;}
.w-fit-content {width: fit-content;}
.w-90percent {width: 90%;}
.w-100percent, .w-full {width: 100%;}
.bottom-12 {bottom: 12px;}
.bottom-20 {bottom: 20px;}
.bottom-22 {bottom: 22px;}
.left-0 {left: 0px;}
.left-8 {left: 8px;}
.left-12 {left: 12px;}
.left-15 {left: 15px;}
.left-20 {left: 20px;}
.left-22 {left: 22px;}
.left-28 {left: 28px;}
.left-50 {left: 50px;}
.left-102 {left: 102px;}
.left-1230 {left: 1230px;}
.top-0 {top: 0px;}
.top-8 {top: 8px;}
.top-12 {top: 12px;}
.top-16 {top: 16px;}
.top-17 {top: 17px;}
.top-18 {top: 19px;}
.top-20 {top: 20px;}
.top-22 {top: 22px;}
.top-30 {top: 30px;}
.top-40 {top: 40px;}
.top-44 {top: 44px;}
.top-68 {top: 68px;}
.right-0 {right: 0px;}
.right-12 {right: 12px;}
.right-18 {right: 18px;}
.right-22 {right: 22px;}
.right-28 {right: 28px;}
.right-68 {right: 68px;}
.min-w-20px {min-width: 20px;}
.min-w-130px {min-width: 130px;}
.min-w-192px {min-width: 192px;}
.min-w-400px {min-width: 400px;}
.max-w-32px {max-width: 32px;}
.max-w-48px {max-width: 48px;}
.max-w-130px {max-width: 130px;}
.max-w-890px {max-width: 890px;}
.max-w-1280px {max-width: 1280px;}
.min-h-20px {min-height: 20px;}
.min-h-120px {min-height: 120px;}
.min-h-188px {min-height: 188px;}
.min-h-200px {min-height: 200px;}
.min-h-240px {min-height: 240px;}
.min-h-250px {min-height: 250px;}
.min-h-400px {min-height: 400px;}
.min-h-100percent {height: 100%;}
.min-h-70screen {height: 70vh;}
.min-w-available {min-width: -webkit-fill-available;}
.max-h-32px {max-height: 32px;}
.max-h-48px {max-height: 48px;}
.max-h-32px {max-height: 32px;}
.max-h-36px {max-height: 36px;}
.max-h-76px {max-height: 76px;}
.max-h-300px {max-height: 300px;}
