.pd-0, .p-0 {padding: 0px;}
.pd-2, .p-2 {padding: 2px;}
.p-3 {padding: 3px;}
.pd-4, .p-4 {padding: 4px;}
.pd-5 {padding: 5px;}
.pd-6, .p-6 {padding: 6px;}
.pd-8, .p-8 {padding: 8px;}
.pd-10, .p-10 {padding: 10px;}
.pd-12, .p-12 {padding: 12px;}
.pd-15 {padding: 15px;}
.pd-18 {padding: 18px;}
.pd-20 {padding: 20px;}
.pd-22 {padding: 22px;}
.pd-24, .p-24 {padding: 24px;}
.pd-32 {padding: 32px;}
.pd-48 {padding: 48px;}
.pd-left-4 {padding-left: 4px;}
.pd-left-11 {padding-left: 11px;}
.pd-left-15 {padding-left: 15px;}
.pl-0 {padding-left: 0px;}
.pdl-2 {padding-left: 2px;}
.pdl-4, .pl-4 {padding-left: 4px;}
.pdl-5 {padding-left: 5px;}
.pdl-6, .pl-6 {padding-left: 6px;}
.pdl-8, .pl-8 {padding-left: 8px;}
.pdl-10, .pl-10 {padding-left: 10px;}
.pdl-12, .pl-12 {padding-left: 12px;}
.pdl-20 {padding-left: 20px;}
.pdl-22 {padding-left: 22px;}
.pdl-25 {padding-left: 25px;}
.pdl-40 {padding-left: 40px;}
.pdl-45 {padding-left: 45px;}
.pd-top-4 {padding-top: 4px;}
.pdt-0 {padding-top: 0px;}
.pt-2 {padding-top: 2px;}
.pdt-3 {padding-top: 3px;}
.pdt-4, .pt-4 {padding-top: 4px;}
.pt-5 {padding-top: 5px;}
.pdt-6 {padding-top: 6px;}
.pt-6 {padding-top: 6px;}
.pdt-8, .pt-8 {padding-top: 8px;}
.pdt-12, .pt-12 {padding-top: 12px;}
.pdt-14 {padding-top: 14px;}
.pt-16 {padding-top: 16px;}
.pdt-17 {padding-top: 17px;}
.pdt-20 {padding-top: 20px;}
.pd-top-22 {padding-top: 22px;}
.pdt-24 {padding-top: 24px;}
.pt-40 {padding-top: 40px;}
.pt-50 {padding-top: 50px;}
.pt-80 {padding-top: 80px;}
.pb-1 {padding-bottom: 1px;}
.pb-2 {padding-bottom: 2px;}
.pd-bottom-4 {padding-bottom: 4px;}
.pdb-4, .pb-4 {padding-bottom: 4px;}
.pb-5 {padding-bottom: 5px;}
.pdb-6, .pb-6 {padding-bottom: 6px;}
.pdb-8 {padding-bottom: 8px;}
.pb-10 {padding-bottom: 10px;}
.pdb-12 {padding-bottom: 12px;}
.pdb-13 {padding-bottom: 13px;}
.pdb-19 {padding-bottom: 19px;}
.pdb-20 {padding-bottom: 20px;}
.pd-bottom-22 {padding-bottom: 22px;}
.pb-40 {padding-bottom: 40px;}
.pd-right-11 {padding-right: 11px;}
.pd-right-12 {padding-right: 12px;}
.pdr-4, .pr-4 {padding-right: 4px;}
.pdr-5 {padding-right: 5px;}
.pdr-6, .pr-6 {padding-right: 6px;}
.pdr-8 {padding-right: 8px;}
.pdr-10, .pr-10 {padding-right: 10px;}
.pdr-12, .pr-12 {padding-right: 12px;}
.pdr-22 {padding-right: 22px;}
.pdr-40 {padding-right: 40px;}
.p-2-8 {padding: 2px 8px;}
.p-4-6 {padding: 4px 6px;}
.p-4-8 {padding: 4px 8px;}
.p-5-10 {padding: 5px 10px;}
.p-6-10 {padding: 6px 10px;}
.p-5-15 {padding: 5px 15px;}
.p-8-16 {padding: 8px 16px;}
.p-10-12 {padding: 10px 12px;}
.p-10-16 {padding: 10px 16px;}
.p-10-20 {padding: 10px 20px;}

.p-block-0 {padding-block: 0px;}
.p-inline-0 {padding-inline: 0px;}
.px-8 {padding-left: 8px;padding-right: 8px;}
.px-24 {padding-left: 24px;padding-right: 24px;}
.py-4 {padding-top: 4px;padding-bottom: 4px;}
.py-24 {padding-top: 24px;padding-bottom: 24px;}